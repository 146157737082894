<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import dsOHelpPages from 'o365.modules.dataObjects.dsOHelpPages.ts';
    import vDraggable from 'o365.vue.directive.draggable.ts';
    import vResizable from 'o365.vue.directive.resizable.ts';
    import $t from 'o365.modules.translate.ts';

    const props = defineProps({
        showTitle:{
            type:Boolean,
            default:false
        }
    });

    const dialogIframe = ref(null);
    const currentHelpPage = ref(null);
    const helpPageDialog = ref(null);
    const helpPageModal = ref(null);
    const helpPageDialogHandle = ref(null)
    const draggableValue = ref({});
    const resizableValue = ref({
        minWidth: 800,
        minHeight: 600
    });

    function openHelpPageInDialog(helpPage: any) {
        currentHelpPage.value = helpPage;

        helpPageModal.value = new bootstrap.Modal(helpPageDialog.value, {
            backdrop: !(helpPage.DisableFocus ?? false),
            focus: true,
            keyboard: false
        });

        helpPageModal.value.show();
    }

    function submitHelpPageDialog() {
        const iframeFunction = currentHelpPage.value?.contentWindow?.submitFromDialog;

        if (typeof iframeFunction !== 'function') {
            // TODO: Write better error handling
            console.error('Help page is not setup correctly. Window is missing function "submitFromDialog"');
            alert('Failed to submit. Help page is setup incorrectly');
            return;
        }

        iframeFunction(hideHelpPageDialog);
    }

    function hideHelpPageDialog() {
        helpPageModal.value.hide();
    }

    function onHelpPageDialogHide() {
        currentHelpPage.value = null;
    }

    onMounted(() => {
        helpPageDialog.value.addEventListener('hidden.bs.modal', onHelpPageDialogHide);

        draggableValue.value = {
            handle: helpPageDialogHandle.value,
            resetInitialPos: false
        };
    });

    onBeforeUnmount(() => {
        helpPageDialog.value.removeEventListener('hidden.bs.modal', onHelpPageDialogHide);
    });

    dsOHelpPages.load();
</script>

<template>
    <div class="dropdown">
        <a class="nav-link active" role="button" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-question-lg"></i>
            <span class="ms-2" v-if="showTitle">
                {{ $t("Help") }}
            </span>
        </a>

        <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" href="https://docs.omega365.com/?Area-ID=10003">General Help</a></li>

            <li v-if="dsOHelpPages.data.length > 0"><hr class="dropdown-divider"></li>

            <li v-for="(helpPage, index) in dsOHelpPages.data">
                <a class="dropdown-item" :href="helpPage.ShowInDialog ? undefined : helpPage.URLLocalized" @click="helpPage.ShowInDialog ? openHelpPageInDialog(helpPage) : undefined">{{ helpPage.NameLocalized ?? helpPage.URL }}</a>
            </li>
        </ul>
    </div>

    <Teleport to="body">
        <div class="modal" ref="helpPageDialog" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content" v-draggable="draggableValue" v-resizable="resizableValue" style="width: 800px; height: 600px;">
                    <div class="modal-header" ref="helpPageDialogHandle">
                        <h5 class="modal-title">{{ currentHelpPage?.NameLocalized }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex p-0">
                        <iframe ref="dialogIframe" class="flex-1 w-100" :src="currentHelpPage?.URLLocalized"></iframe>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="submitHelpPageDialog()" v-if="!currentHelpPage?.HideSubmit">{{ $t('Submit') }}</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ currentHelpPage?.HideSubmit ? $t('Close') : $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>

</style>
